import {
  Grid,
  MenuItem,
  Select,
  Typography,
  Box,
  makeStyles,
  Hidden,
  TextField
} from "@material-ui/core";
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  IApplicantData,
  IIncome,
  IAddress,
  ISSN,
} from "../../container/CreditApplicationContainer/typing";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import * as DateTime from "react-datetime";
import STATES from "../../constant/states";
import INCOMEFREQUENCY from "../../constant/incomeFrequency";

interface IApplicantInfo {
  applicantData: IApplicantData;
  setState: React.Dispatch<React.SetStateAction<IApplicantData>>;
  birthDay: Date | null;
  setBirthDay: React.Dispatch<React.SetStateAction<Date | null>>
  SSN: ISSN;
  confirmSSN: ISSN;
  income: IIncome;
  setIncome: React.Dispatch<React.SetStateAction<IIncome>>;
  setSSN: React.Dispatch<React.SetStateAction<ISSN>>;
  setConfirmSSN: React.Dispatch<React.SetStateAction<ISSN>>;
  primaryResidence: IAddress;
  setPrimaryResidence: React.Dispatch<React.SetStateAction<IAddress>>;
  installationAddress: IAddress;
  setInstallationAddress: React.Dispatch<React.SetStateAction<IAddress>>;
  formRef?: React.RefObject<ValidatorForm>;
}

const MONTHS = [
  { label: "January", value: 0 },
  { label: "February", value: 1 },
  { label: "March", value: 2 },
  { label: "April", value: 3 },
  { label: "May", value: 4 },
  { label: "June", value: 5 },
  { label: "July", value: 6 },
  { label: "August", value: 7 },
  { label: "September", value: 8 },
  { label: "October", value: 9 },
  { label: "November", value: 10 },
  { label: "December", value: 11 },
];

const DAYS = Array.from(new Array(31), (_, index) => ({
  value: index + 1,
  label: index + 1,
}));

const YEARS = Array.from(new Array(86), (_, index) => ({
  value: index + 1920,
  label: index + 1920,
}));

const useStyles = makeStyles({
  root: {
    marginBottom: "40px",
  },
  labelContainer: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
  },
  labelContainerCenter: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "end",
  },
  label: {
    marginBottom: "4px",
    fontWeight: 500,
  },
  margin: {
    marginTop: "40px"
  }
});

const CustomLabel = ({ children }: { children: any }) => {
  const classes = useStyles();
  return (
    <Hidden xsDown>
      <Box className={classes.labelContainer}>
        <Typography className={classes.label}>{children}</Typography>
      </Box>
    </Hidden>
  );
};

const handleFocusNextSSN = (target: EventTarget, formElements: Element[]) => {
  const index = formElements.findIndex((el) => el === target);

  if (index === -1) return;

  const nextElement = formElements[index + 1] as HTMLInputElement;
  nextElement.focus();
  nextElement.select();
};

const ApplicantInfo = ({
  applicantData,
  installationAddress,
  setInstallationAddress,
  primaryResidence,
  setPrimaryResidence,
  setState,
  SSN,
  birthDay,
  setBirthDay,
  setSSN,
  setConfirmSSN,
  confirmSSN,
  income,
  setIncome,
  formRef,
}: IApplicantInfo) => {
  const classes = useStyles();

  const getWholeSSNString = (SSN: ISSN) => {
    return SSN.a
      ? SSN.b
        ? SSN.c
          ? SSN.a + SSN.b + SSN.c
          : SSN.a + SSN.b
        : SSN.a
      : "";
  };

  const onSSNChange = (
    e: React.FormEvent<{}>,
    prevState: ISSN,
    setState: React.Dispatch<React.SetStateAction<ISSN>>
  ) => {
    const { value, maxLength, name, form, id } = e.target as HTMLInputElement;
    const formElements = form ? Array.from(form.elements) : [];

    if (value?.length === maxLength && formElements.length) {
      handleFocusNextSSN(e.target, formElements);
    }

    setState({ ...prevState, [name]: value });

    if (formRef?.current && !id.includes("confirm")) {
      const { childs: children } = formRef.current as any;
      const foundConfirmInput = children.find(
        (child: TextValidator) => child.props.id === id + "-confirm"
      );

      if (foundConfirmInput?.state.value === "") return;

      foundConfirmInput?.state.value === value
        ? foundConfirmInput.makeValid()
        : foundConfirmInput.validate(value); // == foundConfirmInput.makeInvalid() but it will by pass react-form-validator-core error
    }
  };

  const handleBirthdayChange = (date: Date | null) => {
    setBirthDay(date);
  }

  const nameExp = new RegExp("^[A-Za-z0-9 \.\'\-]+$");

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <CustomLabel>Name</CustomLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextValidator
            margin="none"
            required
            id="firstName"
            name="firstName"
            label="First name"
            value={applicantData.firstName}
            //eslint-disable-next-line
            validators={["maxStringLength:24", "minStringLength:2", `matchRegexp:^[A-Za-z ]+$`]}
            errorMessages={[
              "Max length 24 characters",
              "Min length 2 characters",
              "Please enter a valid first name"
            ]}
            onChange={(e) =>
              setState({
                ...applicantData,
                firstName: (e.target as HTMLInputElement).value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextValidator
            id="middleName"
            name="middleName"
            label="Middle name"
            fullWidth
            value={applicantData.middleName}
            onChange={(e) =>
              setState({
                ...applicantData,
                middleName: (e.target as HTMLInputElement).value,
              })
            }
            validators={["maxStringLength:1", `matchRegexp:^[A-Za-z ]+$`]}
            errorMessages={[
              "Max length 1 character",
              "Please enter a valid middle initial"
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextValidator
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            value={applicantData.lastName}
            onChange={(e) =>
              setState({
                ...applicantData,
                lastName: (e.target as HTMLInputElement).value,
              })
            }
            //eslint-disable-next-line
            validators={["required", "maxStringLength:24", `matchRegexp:^[A-Za-z0-9 \.\'\-]+$`]}
            errorMessages={[
              "This field is required",
              "Max length 24 characters",
              "Please enter a valid last name"
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <CustomLabel>Email / Phone</CustomLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextValidator
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            value={applicantData.email}
            //eslint-disable-next-line
            validators={["required", `matchRegexp:^[a-zA-Z0-9_\+\&\*\-]+(?:\.[a-zA-Z0-9_\+\&\*\-]+)*@(?:[a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,7}$`]}
            errorMessages={["This field is required", "Please enter a valid email"]}
            onChange={(e) =>
              setState({
                ...applicantData,
                email: (e.target as HTMLInputElement).value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            required
            id="phone"
            name="phone"
            label="Phone number"
            fullWidth
            value={applicantData.phoneNumber}
            onChange={(e) =>
              setState({
                ...applicantData,
                phoneNumber: (e.target as HTMLInputElement).value,
              })
            }
            inputProps={{
              minLength: 10,
              inputMode: "numeric",
            }}
            validators={["required", "maxStringLength:10", "minStringLength:10", `matchRegexp:^(?!([0-9])\\1{9})[2-9][0-8][0-9][2-9][0-9][0-9][0-9][0-9][0-9][0-9](?<!555[0-9]1[0-9][0-9])$`]}
            errorMessages={[
              "This field is required",
              "This should be 10 digits",
              "This should be 10 digits",
              "Please enter a valid phone number"
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <CustomLabel>Date of birth</CustomLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                required
                label="MM/dd/yyyy"
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={birthDay}
                onChange={handleBirthdayChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Hidden xsDown>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            <CustomLabel>Social Security Number</CustomLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextValidator
              required
              type="password"
              id="ssn-1"
              name="a"
              label="3-digits"
              inputProps={{
                maxLength: 3,
                inputMode: "numeric",
              }}
              fullWidth
              value={SSN.a || ""}
              onChange={(e) => onSSNChange(e, SSN, setSSN)}
              validators={["required", "isNumber", "minStringLength:3"]}
              errorMessages={[
                "This field is required",
                "This should be a number",
                "This should be 3 digits",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextValidator
              required
              id="ssn-2"
              name="b"
              label="2-digits"
              fullWidth
              inputProps={{
                maxLength: 2,
                inputMode: "numeric",
              }}
              type="password"
              value={SSN.b || ""}
              onChange={(e) => onSSNChange(e, SSN, setSSN)}
              validators={["required", "isNumber", "minStringLength:2"]}
              errorMessages={[
                "This field is required",
                "This should be a number",
                "This should be 2 digits",
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextValidator
              required
              id="ssn-3"
              name="c"
              label="4-digits"
              fullWidth
              inputProps={{
                maxLength: 4,
                inputMode: "numeric",
              }}
              type="password"
              value={SSN.c || ""}
              onChange={(e) => onSSNChange(e, SSN, setSSN)}
              validators={["required", "isNumber", "minStringLength:4"]}
              errorMessages={[
                "This field is required",
                "This should be a number",
                "This should be 4 digits",
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            <CustomLabel>Social Security Number (confirm) </CustomLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextValidator
              required
              id="ssn-1-confirm"
              name="a"
              label="3-digits"
              fullWidth
              inputProps={{
                maxLength: 3,
                inputMode: "numeric",
              }}
              onFocus={(e: any) => (e.target.type = "text")}
              onBlur={(e: any) => (e.target.type = "password")}
              type="password"
              value={confirmSSN.a || ""}
              onChange={(e) => onSSNChange(e, confirmSSN, setConfirmSSN)}
              validators={[
                "required",
                "isNumber",
                "isPrimarySsnAMatch",
              ]}
              errorMessages={[
                "This field is required",
                "This should be a number",
                "Confirm number not match",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextValidator
              required
              id="ssn-2-confirm"
              name="b"
              label="2-digits"
              fullWidth
              inputProps={{
                maxLength: 2,
                inputMode: "numeric",
              }}
              onFocus={(e: any) => (e.target.type = "text")}
              onBlur={(e: any) => (e.target.type = "password")}
              type="password"
              value={confirmSSN.b || ""}
              onChange={(e) => onSSNChange(e, confirmSSN, setConfirmSSN)}
              validators={[
                "required",
                "isNumber",
                "isPrimarySsnBMatch",
              ]}
              errorMessages={[
                "This field is required",
                "This should be a number",
                "Confirm number not match",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextValidator
              required
              id="ssn-3-confirm"
              name="c"
              label="4-digits"
              fullWidth
              inputProps={{
                maxLength: 4,
                inputMode: "numeric",
              }}
              onFocus={(e: any) => (e.target.type = "text")}
              onBlur={(e: any) => (e.target.type = "password")}
              type="password"
              value={confirmSSN.c || ""}
              onChange={(e) => onSSNChange(e, confirmSSN, setConfirmSSN)}
              validators={[
                "required",
                "isNumber",
                "isPrimarySsnCMatch",
              ]}
              errorMessages={[
                "This field is required",
                "This should be a number",
                "Confirm number not match",
              ]}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextValidator
              required
              type="password"
              id="ssn-all"
              name="ssn-all"
              label="Social Security Number"
              inputProps={{
                maxLength: 9,
                inputMode: "numeric",
                pattern: "\\d*",
              }}
              fullWidth
              value={getWholeSSNString(SSN)}
              onChange={(e) =>
                setSSN({
                  a: (e.target as HTMLInputElement).value?.substr(0, 3),
                  b: (e.target as HTMLInputElement).value?.substr(3, 2),
                  c: (e.target as HTMLInputElement).value?.substr(5, 4),
                })
              }
              validators={["required", "isNumber", "minStringLength:9"]}
              errorMessages={[
                "This field is required",
                "This should be a number",
                "This should be 9 digits",
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextValidator
              required
              id="ssn-all-confirm"
              type="password"
              name="ssn-all-confirm"
              label="Social Security Number (confirm)"
              inputProps={{
                maxLength: 9,
                inputMode: "numeric",
                pattern: "\\d*",
              }}
              onFocus={(e: any) => (e.target.type = "text")}
              onBlur={(e: any) => (e.target.type = "password")}
              fullWidth
              value={getWholeSSNString(confirmSSN)}
              onChange={(e) =>
                setConfirmSSN({
                  a: (e.target as HTMLInputElement).value?.substr(0, 3),
                  b: (e.target as HTMLInputElement).value?.substr(3, 2),
                  c: (e.target as HTMLInputElement).value?.substr(5, 4),
                })
              }
              validators={[
                "required",
                "isNumber",
                "isPrimarySsnWholeMatch",
              ]}
              errorMessages={[
                "This field is required",
                "This should be a number",
                "Confirm number not match",
              ]}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            <CustomLabel>Income</CustomLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextValidator
              required
              id="income-1"
              name="a"
              label="Individual Income *$"
              inputProps={{
                maxLength: 25,
                minLength: 2
              }}
              fullWidth
              value={income.individualIncome || ""}
              onChange={(e) =>
                setIncome({ ...income, individualIncome: (e.target as HTMLInputElement).value, })
              }
              //eslint-disable-next-line
              validators={["required", `matchRegexp:^[0-9\.]+$`]}
              errorMessages={[
                "This field is required",
                "Please enter a valid income"
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="income-frequency">
                Income Frequency *
              </InputLabel>
              <Select
                required
                native
                inputProps={{
                  name: "income-frequency",
                  id: "income-frequency",
                }}
                fullWidth
                value={income.incomeFrequency || ""}
                onChange={(e) => {

                  setIncome({ ...income, incomeFrequency: (e.target as HTMLInputElement).value });
                }
                }
              >
                <option aria-label="None" value="" />
                {INCOMEFREQUENCY.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Typography component="h3" variant="h6" align="left" className={classes.margin}>
          Installation Address
        </Typography>
      </Hidden>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <CustomLabel>Address line 1</CustomLabel>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextValidator
            required
            id="installation-address1"
            name="installation-address1"
            label="Address line 1"
            fullWidth
            value={installationAddress.addressLine1}
            onChange={(e) => 
            setInstallationAddress({
              ...installationAddress,
              addressLine1: (e.target as HTMLInputElement).value
            })
            }
            //eslint-disable-next-line
            validators={["required", "maxStringLength:60", "minStringLength:2", `matchRegexp:^[A-Za-z0-9 \.\#\(\)\/]+$`]}
            errorMessages={[
              "This field is required",
              "Max length 60 characters",
              "Min length 2 characters",
              "Not a valid address"
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <CustomLabel>Address line 2</CustomLabel>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextValidator
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
            value={installationAddress.addressLine2}
            onChange={(e) => 
            setInstallationAddress({
              ...installationAddress,
              addressLine2: (e.target as HTMLInputElement).value
            })
            }
            inputProps={{
              minLength: 2,
              maxLength: 60
            }}
            //eslint-disable-next-line
            validators={[`matchRegexp:^[A-Za-z0-9 \.\#\(\)\/]+$`]}
            errorMessages={[
              "Please enter a valid address",
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <CustomLabel>City / State / Zip</CustomLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextValidator
            required
            id="installation-city"
            name="installation-city"
            label="City"
            fullWidth
            autoComplete="shipping city"
            value={installationAddress.city}
            pattern="[A-Za-z0-9 \.']+"
            onChange={(e) =>
              setInstallationAddress({
                ...installationAddress,
                city: (e.target as HTMLInputElement).value
              })
            }
            validators={["required", "maxStringLength:40", "minStringLength:2"]}
            errorMessages={[
              "This field is required",
              "Max length 40 characters",
              "Min length 2 characters"
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextValidator
            required
            name="installation-state"
            id="installation-state"
            select
            label="State"
            fullWidth
            value={installationAddress.state?.toUpperCase() || ""}
            onChange={(e) =>
              setInstallationAddress({
                ...installationAddress,
                state: (e.target as HTMLInputElement).value
              })
            }
          >
            {STATES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextValidator>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextValidator
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            value={installationAddress.zipcode?.toString() || ""}
            onChange={(e) =>
              setInstallationAddress({
                ...installationAddress,
                zipcode: (e.target as HTMLInputElement).value
              })
            }
            validators={["required", "isNumber", "maxStringLength:5", "minStringLength:5"]}
            errorMessages={[
              "This field is required",
              "This should be a number",
              "Max length 5 digits",
              "Min length 5 digits",
            ]}
          />
        </Grid>
      </Grid>
      <Hidden xsDown>
        <Typography component="h3" variant="h6" align="left" className={classes.margin}>
          Primary Residence
        </Typography>
      </Hidden>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <CustomLabel>Address line 1</CustomLabel>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextValidator
            required
            id="residence-address1"
            name="residence-address1"
            label="Address line 1"
            fullWidth
            value={primaryResidence.addressLine1}
            onChange={(e) => {
              setPrimaryResidence({
                ...primaryResidence,
                addressLine1: (e.target as HTMLInputElement).value,
              })
            }
            }
            //eslint-disable-next-line
            validators={["required", "maxStringLength:60", "minStringLength:2", `matchRegexp:^[A-Za-z0-9 \.\#\(\)\/]+$`]}
            errorMessages={[
              "This field is required",
              "Max length 60 characters",
              "Min length 2 characters",
              "Not a valid address"
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <CustomLabel>Address line 2</CustomLabel>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextValidator
            id="residence-address2"
            name="residence-address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
            value={primaryResidence.addressLine2}
            onChange={(e) =>
              setPrimaryResidence({
                ...primaryResidence,
                addressLine2: (e.target as HTMLInputElement).value,
              })
            }
            inputProps={{
              minLength: 2,
              maxLength: 60
            }}
            //eslint-disable-next-line
            validators={[`matchRegexp:^[A-Za-z0-9 \.\#\(\)\/]+$`]}
            errorMessages={[
              "Not a valid address",
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <CustomLabel>City / State / Zip</CustomLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextValidator
            required
            id="residence-city"
            name="residence-city"
            label="City"
            fullWidth
            autoComplete="shipping city"
            value={primaryResidence.city}
            pattern="[A-Za-z0-9 \.']+"
            onChange={(e) =>
              setPrimaryResidence({
                ...primaryResidence,
                city: (e.target as HTMLInputElement).value,
              })
            }
            validators={["required", "maxStringLength:40", "minStringLength:2"]}
            errorMessages={[
              "This field is required",
              "Max length 40 characters",
              "Min length 2 characters"
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="residence-state">
              State *
            </InputLabel>
            <Select
              required
              native
              inputProps={{
                name: "residence-state",
                id: "residence-state",
              }}
              value={primaryResidence.state}
              onChange={(e) =>
                setPrimaryResidence({
                  ...primaryResidence,
                  state: (e.target as HTMLInputElement).value,
                })
              }
            >
              <option aria-label="None" value="" />
              {STATES.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextValidator
            required
            id="residence-zip"
            name="residence-zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            value={primaryResidence.zipcode?.toString() || ""}
            onChange={(e) =>
              setPrimaryResidence({
                ...primaryResidence,
                zipcode: (e.target as HTMLInputElement).value
              })
            }
            validators={["required", "isNumber", "maxStringLength:5", "minStringLength:5"]}
            errorMessages={[
              "This field is required",
              "This should be a number",
              "Max length 5 digits",
              "Min length 5 digits",
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ApplicantInfo;
